import { client } from '@admin/libraries/apollo';
import {
  Admin__CouponFragment,
  useCouponActivateMutation,
  useCouponDeactivateMutation,
  usePaginatedCouponsQuery,
} from '@admin/schema';
import { AnchorButton as Link, Button, Col, Row, Table } from '@shared/components/bootstrap';
import React, { useState } from 'react';
import { Spinner } from '@admin/components/spinner';
import { Timestamp } from '@shared/components/helpers';
import { Currency } from '@shared/components/helpers/currency';
import { Percent } from '@shared/components/helpers/percent';
import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { InputFormGroup, SelectFormGroup } from '@admin/components/fields';
import { useDebounce } from '@shared/hooks';
import { DateTime } from 'luxon';
import { Titleize } from '../helpers/titleize';

const formatDate = (time?: string) => (time ? <Timestamp value={time} format={Timestamp.Format.Date} /> : 'N/A');

const ActivateButton: React.FC<{ id: string }> = ({ id }) => {
  const [execute, { loading }] = useCouponActivateMutation({ client, variables: { id } });

  return (
    <Button kind="primary" onClick={() => execute()} loading={loading}>
      Activate
    </Button>
  );
};

const DeactivateButton: React.FC<{ id: string }> = ({ id }) => {
  const [execute, { loading }] = useCouponDeactivateMutation({ client, variables: { id } });

  return (
    <Button kind="danger" onClick={() => execute()} loading={loading}>
      Deactivate
    </Button>
  );
};

enum Status {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive',
}

const STATUS_TO_ACTIVE: Record<Status, boolean | undefined> = {
  [Status.All]: undefined,
  [Status.Active]: true,
  [Status.Inactive]: false,
};

export const Dashboard: React.FC = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<string | undefined>();
  const [status, setStatus] = useState<Status>(Status.All);
  const { data, loading } = usePaginatedCouponsQuery({
    client,
    variables: {
      page,
      filters: {
        query: useDebounce(query),
        active: STATUS_TO_ACTIVE[status],
      },
    },
    fetchPolicy: 'network-only',
  });
  const coupons = data?.paginatedCoupons;

  const percent = (coupon: Admin__CouponFragment) =>
    coupon.amount === 0.0 && coupon.percent * 1 > 0 && coupon.percent * 1 <= 1;
  const dollar = (coupon: Admin__CouponFragment) => coupon.percent === 0.0 && coupon.amount * 1 > 0;
  return (
    <>
      <Panel>
        <Panel.Body>
          <Row>
            <Col md={9}>
              <InputFormGroup
                id="filter_query"
                label="Search:"
                placeholder="Search by description or code"
                value={query ?? ''}
                onChange={(event) => setQuery(event.target.value || undefined)}
              />
            </Col>
            <Col md={3}>
              <SelectFormGroup
                id="filter_status"
                label="Status:"
                value={status}
                onChange={(event) => setStatus(event.target.value as Status)}
              >
                <option value={Status.All}> - All - </option>
                <option value={Status.Active}>Active</option>
                <option value={Status.Inactive}>Inactive</option>
              </SelectFormGroup>
            </Col>
          </Row>
        </Panel.Body>
        <Panel.Footer align="right">
          <Link kind="mint" href="/marketing/coupons/new">
            Add
          </Link>
        </Panel.Footer>
      </Panel>

      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-1">ID</th>
                <th className="col-sm-1">Stripe Name</th>
                <th className="col-sm-1">Description</th>
                <th className="col-sm-1">Amount</th>
                <th className="col-sm-1">Promo Code</th>
                <th className="col-sm-1">Effective Date</th>
                <th className="col-sm-2">Appointment Date Cutoff</th>
                <th className="col-sm-1">Duration</th>
                <th className="col-sm-1">Eligible Services</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {coupons?.results.map((coupon) => (
                <tr key={coupon.id}>
                  <td className="col-sm-1">{coupon.id}</td>
                  <td className="col-sm-1">{coupon.stripeId}</td>
                  <td className="col-sm-3">{coupon.name}</td>
                  <td className="col-sm-1">
                    {percent(coupon) && <Percent value={coupon.percent} />}{' '}
                    {dollar(coupon) && (
                      <>
                        <Currency value={coupon.amount} /> {coupon.currency}
                      </>
                    )}
                  </td>
                  <td>{coupon.code}</td>
                  <td className="col-sm-1">{formatDate(coupon.effective ?? undefined)}</td>
                  <td className="col-sm-1 text-capitalize">
                    {coupon.scheduledCutoff
                      ? DateTime.fromISO(coupon.scheduledCutoff).toLocaleString(DateTime.DATE_SHORT)
                      : '-'}
                  </td>
                  <td className="col-sm-1">
                    {coupon.duration} {coupon.durationInMonths && <div>{coupon.durationInMonths} month(s)</div>}
                  </td>
                  <td className="col-sm-1 text-capitalize">
                    <Titleize text={coupon.eligibleServices.join(', ')} />
                  </td>
                  <td className="col-zero">
                    <Link kind="info" href={`/marketing/coupons/${coupon.id}/edit`}>
                      Edit
                    </Link>
                  </td>
                  <td className="col-zero">
                    {coupon.active ? <DeactivateButton id={coupon.id} /> : <ActivateButton id={coupon.id} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
          {coupons && <Pagination onPage={setPage} pagination={coupons.pagination} />}
        </Panel.Body>
      </Panel>
    </>
  );
};
