import { vehicleTypesURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { useVehicleTypeCreateMutation, VehicleTypeInput } from '@admin/schema';
import * as React from 'react';
import { VehicleTypeForm } from './vehicle_type_form';

export const CreateVehicleType: React.FC = () => {
  const [error, setError] = React.useState<string | undefined>();

  const [vehicleTypeCreate] = useVehicleTypeCreateMutation({ client });

  const onSubmit = async (vehicleType: VehicleTypeInput) => {
    const result = await vehicleTypeCreate({
      variables: {
        input: {
          name: vehicleType.name,
          cuft: vehicleType.cuft,
          numSeats: vehicleType.numSeats,
          weightInPounds: vehicleType.weightInPounds,
          fieldDispatchable: vehicleType.fieldDispatchable,
          restrictedZipCodes: vehicleType.restrictedZipCodes,
        },
      },
    });

    if (result.data?.vehicleTypeCreate.error) {
      setError(result.data?.vehicleTypeCreate.error);
    } else {
      window.location.href = vehicleTypesURL();
    }
  };

  return (
    <div>
      {error && <div className="alert alert-danger">{error}</div>}
      <VehicleTypeForm onSubmit={onSubmit} />
    </div>
  );
};
