import * as React from 'react';
import { Panel } from '@admin/components/helpers/panel';
import { vehicleTypesURL } from '@admin/config/routes';
import { VehicleTypeInput } from '@admin/schema';
import { FieldFormGroup } from '@shared/components/fields/field_form_group';
import { InputFormControl } from '@shared/components/fields/input_form_control';
import { getZipDifferenceCount, parseZips } from '@admin/utils/zip_cods';
import { ZipInput } from '../edit_zip_input';

const DEFAULT_VEHICLE_TYPE: VehicleTypeInput = {
  name: '',
  numSeats: 0,
  cuft: 0,
  weightInPounds: 0,
  fieldDispatchable: true,
  restrictedZipCodes: [],
};

export const VehicleTypeForm: React.FC<{
  existingVehicleType?: VehicleTypeInput;
  onSubmit(vehicleType: VehicleTypeInput): void;
}> = ({ existingVehicleType, onSubmit }) => {
  const [vehicleType, setVehicleType] = React.useState<VehicleTypeInput>(existingVehicleType || DEFAULT_VEHICLE_TYPE);

  const originalRestrictedZipCodesText = vehicleType.restrictedZipCodes.join('\n');
  const [restrictedZipCodesText, setRestrictedZipCodesText] = React.useState<string>(originalRestrictedZipCodesText);

  const restrictedZipCodesSet = new Set(parseZips(restrictedZipCodesText));
  const originalRestrictedZipCodesSet = new Set(parseZips(originalRestrictedZipCodesText));

  const addedRestrictedZipCodesCount = getZipDifferenceCount(restrictedZipCodesSet, originalRestrictedZipCodesSet);
  const removedRestrictedZipCodesCount = getZipDifferenceCount(originalRestrictedZipCodesSet, restrictedZipCodesSet);

  return (
    <>
      <Panel>
        <Panel.Header>
          <Panel.Title>{existingVehicleType ? 'Edit Vehicle Type' : 'New Vehicle Type'}</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <FieldFormGroup label="Name:">
            <InputFormControl
              type="text"
              id="vehicle_type_name"
              placeholder="A"
              value={vehicleType?.name || ''}
              onChange={(e) => setVehicleType({ ...vehicleType, name: e.target.value })}
            />
          </FieldFormGroup>

          <FieldFormGroup label="Number of seats:">
            <InputFormControl
              type="number"
              id="vehicle_type_num_seats"
              placeholder="3"
              value={vehicleType?.numSeats || ''}
              onChange={(e) => setVehicleType({ ...vehicleType, numSeats: e.target.valueAsNumber })}
            />
          </FieldFormGroup>

          <FieldFormGroup label="Cuft:">
            <InputFormControl
              type="number"
              id="vehicle_type_cuft"
              placeholder="100"
              value={vehicleType?.cuft || ''}
              onChange={(e) => setVehicleType({ ...vehicleType, cuft: e.target.valueAsNumber })}
            />
          </FieldFormGroup>

          <FieldFormGroup label="Weight in pounds:">
            <InputFormControl
              type="number"
              id="vehicle_type_cuft"
              placeholder="100"
              value={vehicleType?.weightInPounds || ''}
              onChange={(e) => setVehicleType({ ...vehicleType, weightInPounds: e.target.valueAsNumber })}
            />
          </FieldFormGroup>

          <FieldFormGroup label="Field Dispatchable:">
            <InputFormControl
              type="checkbox"
              id="fieldDispatchable"
              checked={vehicleType.fieldDispatchable}
              onChange={(e) => setVehicleType({ ...vehicleType, fieldDispatchable: e.target.checked })}
            />
          </FieldFormGroup>

          <FieldFormGroup label="Restricted Zip Codes:">
            <ZipInput
              label=""
              value={restrictedZipCodesText}
              addedZipsCount={addedRestrictedZipCodesCount}
              removedZipsCount={removedRestrictedZipCodesCount}
              disabled={false}
              onChange={(e) => {
                setRestrictedZipCodesText(e.target.value);
              }}
            />
          </FieldFormGroup>
        </Panel.Body>
        <Panel.Footer align="right">
          <a href={vehicleTypesURL()} className="btn btn-default">
            Cancel
          </a>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => {
              onSubmit({ ...vehicleType!, restrictedZipCodes: Array.from(new Set(parseZips(restrictedZipCodesText))) });
            }}
            disabled={
              !(!!vehicleType?.cuft && !!vehicleType?.numSeats && !!vehicleType?.name && !!vehicleType?.weightInPounds)
            }
          >
            Save
          </button>
        </Panel.Footer>
      </Panel>
    </>
  );
};
